<template>
  <div class="done">
    <template v-if="isPending">
      <img src="@/assets/img/loader.svg" />
    </template>

    <template v-if="isSuccess">
      <div class="done__top">
        <img src="@/assets/img/finish-icon.svg" />

        <div class="done__text">
          {{ $t("finish.text") }}
        </div>
      </div>

      <div class="done__invite">
        <div class="done__invite-company">{{ company }}</div>
        <div class="done__invite-address">{{ address }}</div>
        <div class="done__invite-due">{{ due }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Done",

  computed: {
    ...mapState({
      company: ({ user }) => user.company,
      address: ({ user }) => user.address,
      dueFrom: ({ user }) => user.dueFrom,
      dueTo: ({ user }) => user.dueTo,
      isPending: ({ user }) => user.isPendingRegistration,
      isSuccess: ({ user }) => user.isSuccessRegistration,
      isFailure: ({ user }) => user.isFailureRegistration,
      base64Photo: ({ photo }) => photo.base64Photo,
    }),

    due() {
      const tz = this.$moment.tz.guess();
      const dueFromMinutesOffset = this.$moment
        .tz(this.dueFrom, tz)
        .utcOffset();
      const dueToMinutesOffset = this.$moment.tz(this.dueTo, tz).utcOffset();
      const dateFormat = "DD.MM.YYYY HH:mm";

      let text = "";

      if (this.dueFrom) {
        text += `${this.$t("finish.from")} ${this.$moment(this.dueFrom)
          .add(dueFromMinutesOffset, "minutes")
          .format(dateFormat)} `;
      }

      if (this.dueTo) {
        text += `${this.$t("finish.to")} ${this.$moment(this.dueTo)
          .add(dueToMinutesOffset, "minutes")
          .format(dateFormat)}`;
      }

      if (!this.dueFrom && !this.dueTo) {
        text = this.$t("finish.indefinite");
      }

      return text;
    },
  },

  methods: {
    registration() {
      this.$store.dispatch("user/registration", this.base64Photo);
    },
  },

  created() {
    this.registration();
  },
};
</script>
