export default {
  common: {
    send: "Send",
    cancel: "Cancel",
    processing: "Processing",
    sending: "Sending",
    back: "Back",
  },

  pin: {
    enterPin: "Enter PIN",
  },

  numboard: {
    clear: "clear all",
  },

  photo: {
    takePhoto: "Take photo",
    personalDataMessage:
      "By continuing your actions, you agree to the transfer of personal data",
  },

  faceAccess: {
    reshootPhoto: "Reshoot photo",
    providePhoto: "Registration",
    noFaceOnPhotoMessage:
      "Please take a photo where your face is clearly visible",
  },
};
